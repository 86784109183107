import styled from "styled-components";

export const Paragraph = styled.div`
  font-family: "PressStart2P",serif;
  font-size: 34px;
  
  @media only screen and (max-width: 600px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 600px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 28px;
  }
`;
