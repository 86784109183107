import styled from "styled-components";

export const Headline = styled.div`
  font-family: "LadyRadical",serif;
  font-size: 64px;
  
  @media only screen and (max-width: 600px) {
    font-size: 28px;
  }
  @media only screen and (min-width: 600px) {
    font-size: 38px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 48px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 58px;
  }
`;
