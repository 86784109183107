import React, {useEffect, useRef, useState} from 'react';
import styled, { keyframes} from "styled-components";
import {Heading2, Paragraph, Subtitle} from "@/components/typography";

import border from "../assets/images/blank_zelda_border.png";
import {useNavigate} from "react-router-dom";
import MarcButton from "@/components/buttons/MarcButton";

const ResumeContainer = styled.div`
  background: black;
  color: white;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const scrollAnimation = keyframes`
  0% {
    top: 1900px;
  }
  100% {
    top: -2000px;
  }
`;

type CrawlProps = {
    topOverride: number | null
}

const Crawl = styled.div.attrs<CrawlProps>((props: CrawlProps) => (props.topOverride !==null  ? {
    style: {
        top: props.topOverride + "px"
    }
}: {
        style: {
            animationDuration: "35s",
            animationTimingFunction: "linear",
            animationIterationCount: 1,
            animationFillMode: "forwards",
        }
    }
))<CrawlProps>`
  position: relative;
  animation-name: ${scrollAnimation};
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 2800px;
  width: 600px;
  border-image: url(${border})  8 / 32px / 0 round;
  border-width: 32px;
  border-style: inset;
`;

export const Spacer = styled.div`
  padding: 64px;
`;

const ButtonContainer = styled.div`
  width: 200px;
  height: 60px;
, cursor: pointer;
  &:hover {
    color: green;
  }
`;

enum Direction {
    UP,
    DOWN
}

function Resume() {
    const [topPos, setTopPos] = useState<number | null>(null);
    const job1Ref = useRef<HTMLDivElement | null>(null)
    const job2Ref = useRef<HTMLDivElement | null>(null)
    const job3Ref = useRef<HTMLDivElement | null>(null)
    const topRef = useRef<number | null>(0);
    const [enabled, setEnabled] = useState(false);
    const navigate = useNavigate();
    const intervalRef = React.useRef<NodeJS.Timer | null>(null);
    const crawlRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        return () => stopCounter(); // when App is unmounted we should stop counter
    }, []);

    useEffect(() => {
        if (enabled) {
            console.debug("crawlRef.current!.offsetTop: ", crawlRef.current!.offsetTop );
           // setTopPos(crawlRef.current!.offsetTop + 880);
            setTopPos(880);
        }
    }, [enabled])

    const jumpTo = (jobNum: number) => {
        switch (jobNum) {
            case 1:
                setTopPos(1100);
                break;
            case 2:
                setTopPos(100);
                break;
            case 3:
                setTopPos(-910);
                break;
            default:
                setTopPos(880);
        }
    }
    useEffect(() => {
        topRef.current = topPos; // update clicks ref when clicks updates
    }, [topPos]);

    const startCounter = (dir: Direction) => {
        if (intervalRef.current) return;
        intervalRef.current = setInterval(() => {
            if (!enabled) {
                setEnabled(true);
            } else {
                if (dir === Direction.DOWN && topRef.current! <= 1080) {
                    setTopPos( (prevValue) => prevValue! + 5);
                } else if (dir === Direction.UP && topRef.current! >= -1080) {
                    setTopPos( (prevValue) => prevValue! - 5);
                }
            }
        }, 10);
    };

    const stopCounter = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };
    return (
        <ResumeContainer>
            <div style={{display: "flex", flex: 1, height: "100%", paddingTop: "480px"}}>
                <MarcButton
                    onClick={() => navigate("/")}
                    label={"Back"}
                />
            </div>
            <div style={{ display: "flex", flex: 1}} >
                <Crawl ref={crawlRef} topOverride={topPos}>
                    <div style={{flex: 1, flexDirection: "column"}}>
                        <Heading2 style={{textAlign: "center"}} ref={job1Ref}>
                            11/2020 to 5/2022
                        </Heading2>
                        <Heading2 style={{textAlign: "center"}}>
                            Senior FrontEnd Engineer
                        </Heading2>
                        <Subtitle style={{paddingTop: 12, textAlign: "center"}}>
                            Pix.wine
                        </Subtitle>
                        <Paragraph style={{paddingTop: 36}}>
                            * Lead development of a wine discovery platform.
                        </Paragraph>
                        <Paragraph style={{paddingTop: 36}}>
                            * Developed architecture of scalable consumer facing product from inception to beta release.
                        </Paragraph>
                        <Paragraph style={{paddingTop: 36}}>
                            * Selected full stack and worked to build a team of developers.
                        </Paragraph>
                        <Paragraph style={{paddingTop: 36}}>
                            * Stack: React, Typescript, Next.js, XState, Firebase, NodeJS, GraphQL, PostgreSQL
                        </Paragraph>
                    </div>
                    <Spacer style={{width: 30, height: 200 }} />
                    <div style={{flex: 1, flexDirection: "column"}}>
                        <Heading2 style={{textAlign: "center"}}  ref={job2Ref}>
                            1/2019 to 11/2020
                        </Heading2>
                        <Heading2 style={{textAlign: "center"}}>
                            Full Stack Engineer
                        </Heading2>
                        <Subtitle style={{paddingTop: 12, textAlign: "center"}}>
                            Emetry inc.
                        </Subtitle>
                        <Paragraph style={{paddingTop: 36}}>
                            * Developed analytics tools for the wine marketing industry.
                        </Paragraph>
                        <Paragraph style={{paddingTop: 36}}>
                            * Frontend client and mid-tier client development of Web App and for 100’s of users interfacing with
                            massive dataset including millions of data-points.
                        </Paragraph>
                        <Paragraph style={{paddingTop: 36}}>
                            * D3 and MapboxGL integration to create new custom, interactive charts with responsiveness.
                        </Paragraph>
                        <Paragraph style={{paddingTop: 36}}>
                            * Stack: React, Typescript, Redux, Firebase, NodeJS, PostgreSQL
                        </Paragraph>
                    </div>
                    <Spacer style={{width: 30, height: 200 }} />
                    <div style={{flex: 1, flexDirection: "column"}}>
                        <Heading2 style={{textAlign: "center"}} ref={job3Ref}>
                            11/2015 to 12/2019
                        </Heading2>
                        <Heading2 style={{textAlign: "center"}}>
                            Full Stack Engineer
                        </Heading2>
                        <Subtitle style={{paddingTop: 12, textAlign: "center"}}>
                            HCP Inc.
                        </Subtitle>
                        <Paragraph style={{paddingTop: 36}}>
                            * Lead the development of property management software tools.
                        </Paragraph>
                        <Paragraph style={{paddingTop: 36}}>
                            * Developed frontend to interact with multiple data sources.
                        </Paragraph>
                        <Paragraph style={{paddingTop: 36}}>
                            * Created backend services with Google Cloud to source and host database and mid-tiers.
                        </Paragraph>
                        <Paragraph style={{paddingTop: 36}}>
                            * Worked with designers and customers to iterate over product design.
                        </Paragraph>
                        <Paragraph style={{paddingTop: 36}}>
                            * Stack: Javascript, Google Apps Script, Firebase, NoSQL, MySQL
                        </Paragraph>
                    </div>
                </Crawl>
            </div>
            { enabled &&
                <div style={{background: "black", display: "flex", flex: 1, flexDirection: "column", alignItems: "center"}}>
                    <ButtonContainer
                        onMouseDown={() => startCounter(Direction.DOWN)}
                        onMouseUp={stopCounter}
                        onMouseLeave={stopCounter}>
                        <Heading2 style={{textAlign: "center", cursor: "pointer", userSelect: "none"}}>
                            Up
                        </Heading2>
                    </ButtonContainer>
                    <Spacer />
                    <ButtonContainer
                        onMouseDown={() => startCounter(Direction.UP)}
                        onMouseUp={stopCounter}
                        onMouseLeave={stopCounter}>
                        <Heading2 style={{textAlign: "center", cursor: "pointer", userSelect: "none"}}>
                            Down
                        </Heading2>
                    </ButtonContainer>
                    <Spacer />
                    <ButtonContainer
                        onClick={() => jumpTo(1)}>
                        <Heading2 style={{textAlign: "center", cursor: "pointer", userSelect: "none"}}>
                            Job 1
                        </Heading2>
                    </ButtonContainer>
                    <Spacer />
                    <ButtonContainer
                        onClick={() => jumpTo(2)}>
                        <Heading2 style={{textAlign: "center", cursor: "pointer", userSelect: "none"}}>
                            Job 2
                        </Heading2>
                    </ButtonContainer>
                    <Spacer />
                    <ButtonContainer
                        onClick={() => jumpTo(3)}>
                        <Heading2 style={{textAlign: "center", cursor: "pointer", userSelect: "none"}}>
                            Job 3
                        </Heading2>
                    </ButtonContainer>
                </div>
            }
            {!enabled &&
                <div style={{background: "black", display: "flex", flex: 1, flexDirection: "column", alignItems: "center"}}>
                    <ButtonContainer
                        onMouseDown={() => startCounter(Direction.UP)}
                        onMouseUp={stopCounter}
                        onMouseLeave={stopCounter}>
                        <Heading2 style={{textAlign: "center", cursor: "pointer", userSelect: "none"}}>
                            Stop
                        </Heading2>
                    </ButtonContainer>
                </div>
            }
        </ResumeContainer>
    );
}

export default Resume;
