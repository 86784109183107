import styled from "styled-components";

export const Subtitle = styled.div`
  font-family: "PressStart2P",serif;
  font-size: 28px;
  
  @media only screen and (max-width: 600px) {
    font-size: 8px;
  }
  @media only screen and (min-width: 600px) {
    font-size: 10px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 22px;
  }
`;
