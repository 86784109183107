import React from 'react';
import styled from "styled-components";
import MarcButton from "@/components/buttons/MarcButton";
import {useNavigate} from "react-router-dom";
import border from "@/assets/images/blank_zelda_border.png";
import nft from "@/assets/images/agena_pixel.png";
import electronics from "@/assets/images/electronics_pixel.png";
import dungeons from "@/assets/images/dungeons_pixel.png";
import paintbrush from "@/assets/images/paintbrush_pixel2.png";
import soccer from "@/assets/images/pixel_nes_football_np.png";
import {Heading2, Paragraph, Subtitle} from "@/components/typography";
import {Spacer} from "@/features/Resume";

const PageContainer = styled.div`
  background: black;
  color: white;
  width: 100%;
  padding: 48px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const InterestContainer = styled.div`
  position: relative;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  border-image: url(${border})  8 / 32px / 0 round;
  border-width: 32px;
  border-style: inset;
`;


function Interests() {
    const navigate = useNavigate();
    return (
        <PageContainer>
            <div style={{display: "flex", alignItems: "flex-start", flex: 1, height: "100%"}}>
                <MarcButton
                    onClick={() => navigate("/")}
                    label={"Back"}
                />
            </div>
            <div style={{ display: "flex", flex: 1}} >
                <InterestContainer>
                    <div style={{flex: 1, flexDirection: "column"}}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <img src={nft} width={106} height={106} />
                            <Heading2 style={{textAlign: "center", paddingLeft: 24}} >
                                Web 3, NFTs
                            </Heading2>
                        </div>
                        {/*<Subtitle style={{paddingTop: 12, textAlign: "center"}}>
                            HCP Inc.
                        </Subtitle>*/}
                        <Paragraph style={{paddingTop: 36, fontSize: 20}}>
                            Built an iOS and Android app with React Native to
                            Browse your Crypto Coven, show your witches and their artifacts, animated and with audio, and show the lore and the
                            world of the weird wilds.
                        </Paragraph>
                        <Spacer />
                        <div style={{display: "flex", alignItems: "center"}}>
                            <img src={electronics} height={56} />
                            <Heading2 style={{textAlign: "center", paddingLeft: 24}} >
                                Electronics, Optics
                            </Heading2>
                        </div>
                        <Paragraph style={{paddingTop: 36, fontSize: 20}}>
                            Arduino's, microcrontroller chips, playing around with
                            electronics and learning more about electronic engineering
                            Created a physical game using laser tripwires, LEDs and
                            a React Native app to control it all.
                        </Paragraph>
                        <Spacer />
                        <div style={{display: "flex", alignItems: "center"}}>
                            <img src={dungeons} height={86} />
                            <Heading2 style={{textAlign: "center", paddingLeft: 24}} >
                                Tabletop RPGs and retro video gaming
                            </Heading2>
                        </div>
                        <Paragraph style={{paddingTop: 36, fontSize: 20}}>
                            Dungeons and Dragons, Pathfinder, classic
                            NES and SNES. Emulators and original hardware.
                        </Paragraph>
                        <Spacer />
                        <div style={{display: "flex", alignItems: "center"}}>
                            <img src={paintbrush} height={76} />
                            <Heading2 style={{textAlign: "center", paddingLeft: 24}} >
                                Miniature painting
                            </Heading2>
                        </div>
                        <Paragraph style={{paddingTop: 36, fontSize: 20}}>
                            Painting miniatures from Warhammer, D&D and other fantasy & sci-fi settings
                        </Paragraph>
                        <Spacer />
                        <div style={{display: "flex", alignItems: "center"}}>
                            <img src={soccer} height={106} />
                            <Heading2 style={{textAlign: "center", paddingLeft: 24}} >
                                Soccer (Football)
                            </Heading2>
                        </div>
                        <Paragraph style={{paddingTop: 36, fontSize: 20}}>
                            Avid soccer player, and I watch sometimes too.
                        </Paragraph>
                    </div>
                </InterestContainer>
            </div>
                <div style={{background: "black", display: "flex", flex: 1, flexDirection: "column", alignItems: "center"}}>

                </div>
        </PageContainer>
    );
}

export default Interests;
