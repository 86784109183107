import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
    Route,
} from "react-router-dom";
import './index.css';
import App from './App';
import Resume from './features/Resume';
import Interests from './features/Interests';
import reportWebVitals from './reportWebVitals';
import './fonts/LadyRadical2Regular-0yAz.ttf';
import './fonts/PressStart2P-vaV7.ttf';
import './fonts/VerminVibes1989Regular-m77m.ttf';

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />
    },
    {
        path: "/resume",
        element: <Resume />
    },
    {
        path: "/interests",
        element: <Interests />
    },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
