import styled from "styled-components";
import {Heading2} from "@/components/typography";
import React from "react";

const ButtonContainer = styled.div`
  width: 200px;
  height: 60px;
, cursor: pointer;
  &:hover {
    color: green;
  }
`;

interface ButtonProps {
    label: string;
    onClick?: () => void;
    onMouseDown?: () => void;
    onMouseUp?: () => void;
    onMouseLeave?: () => void;
}
export default function MarcButton({onClick, label}: ButtonProps) {
    return (
    <ButtonContainer
        onClick={onClick}>
        <Heading2 style={{textAlign: "center", cursor: "pointer", userSelect: "none"}}>
            {label}
        </Heading2>
    </ButtonContainer>
    )
}
