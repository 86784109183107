import React, {useState} from 'react';
import './App.css';
import styled from "styled-components";
import banner from "./assets/images/pixel_banner_plain_cropped.png";
import wing from "./assets/images/banner_wing.png";
import marc from "./assets/images/marc_transparent.png";
import heart from "./assets/images/heart.png";
import {useNavigate} from "react-router-dom";
import {Headline, Paragraph} from "@/components/typography";

const AppContainer = styled.div`
  background: black;
  color: white;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const SelectItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  cursor: pointer;
`;

const Disappearing = styled.div`
  flex: 1;
  @media only screen and (max-width: 1000px) {
    flex: 0;
    padding-right: 68px;
  }
`;

const Banner = styled.div`
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 330px;
  z-index: 1;
  position: absolute;
  top: 136px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wing = styled.div`
  background-image: url(${wing});
  background-repeat: repeat-x;
  background-size: contain;
  /*background-position: center;*/
  width: 100%;
  height: 162px;
  z-index: 0;
  position: absolute;
  top: 220px;
  left: 0;
  @media (max-width: 768px) {
    visibility: hidden;
  }
`;
const Marc = styled.div`
  background-image: url(${marc});
  background-repeat: no-repeat;
  background-size: contain;
  height: 600px;
  width: 362px;
  z-index: 2;
  position: absolute;
  top: 220px;
  left: 0;
 /* @media only screen and (max-width: 1000px) {
    left: 0;
  }*/
`;

type HeartProps = {
    visible: boolean;
}
const Heart = styled.div<HeartProps>`
  visibility: ${props => props.visible ? "visible": "hidden"};
  background-image: url(${heart});
  background-repeat: no-repeat;
  background-size: contain;
  height: 30px;
  width: 30px;
  padding-right: 48px;
  z-index: 2;
`;



function App() {
    const navigate = useNavigate();
    const [hovering, setHovering] = useState("Resume");
    const handleHover = (selection: string) => {
        setHovering(selection)
    }
    const handleClick = (selection: string) => {
        navigate(selection)
    }
  return (
    <AppContainer>
              <div style={{width: "100%", display: "flex", flex: 1}}>
                  <Banner>
                      <Headline style={{zIndex: 3}}>
                          Marc O Cleirigh
                      </Headline>
                  </Banner>
                  <Wing />
              </div>
        <Marc />
        <div style={{ display: "flex",  width: "100%",flex: 1}}>
            <div style={{flex: 1}}/>
            <SelectContainer >
                <SelectItemContainer
                    onClick={() => handleClick("Resume")}
                    onMouseOver={() => handleHover("Resume")}>
                    <Heart visible={hovering === "Resume"}/>
                    <Paragraph>
                        Resume
                    </Paragraph>
                </SelectItemContainer>
                <SelectItemContainer
                    onClick={() => handleClick("Interests")}
                    onMouseOver={() => handleHover("Interests")}>
                    <Heart visible={hovering === "Interests"}/>
                    <Paragraph>
                        Interests
                    </Paragraph>
                </SelectItemContainer>
            </SelectContainer>
            <Disappearing />
        </div>
        <div style={{display: "flex", paddingBottom: 48}}>
            <Paragraph>TM and © 1988 MOC Industry Co. Ltd.</Paragraph>
        </div>
    </AppContainer>
  );
}

export default App;
